import { apiGetUserList, apiGetGroupTree, apiGetGroupList } from '@/api/index.js'
import { getFilterUserTreeList, sortTreeGroup } from '@/utils/lib.js'

const initialState = () => ({
  groupTree: {},
  groupList: [],
  deviceList: [],
  deviceTreeList: [], // 帶public參數的device tree list
  userList: [],
  userTreeList: [],
  privateDeviceList: [], // private device list (public: 0)
})

const state = initialState()

const mutations = {
  updateDeviceList(state, payload) {
    let sortDevices = payload.sort((a, b) => a.video.title.localeCompare(b.video.title))
    state.deviceList = sortDevices
  },
  updateGroupTree(state, payload) {
    sortTreeGroup(payload)
    state.groupTree = payload
  },
  updateGroupList(state, payload) {
    state.groupList = payload
  },
  updateDeviceTreeList(state, payload) {
    state.deviceTreeList = payload
  },
  updateUserList(state, payload) {
    state.userList = payload
  },
  updateUserTreeList(state, payload) {
    state.userTreeList = payload
  },
  updatePrivateDeviceList(state, payload) {
    state.privateDeviceList = payload
  }
}

const actions = {
  async getDeviceGroupList({ commit }, payload) {
    const resDevices = await apiGetUserList('device', payload.public)
    commit('updateDeviceList', resDevices.data)
    const resTree = await apiGetGroupTree()
    commit('updateGroupTree', resTree.data)
    const resGroupList = await apiGetGroupList()
    commit('updateGroupList', resGroupList.data)
  },
  async getDeviceTreeList({ commit, state, dispatch }, payload) {
    await dispatch('getDeviceGroupList', payload)

    const treeList = getFilterUserTreeList(state.deviceList, state.groupTree, 'videoTitle')
    commit('updateDeviceTreeList', treeList)
  },
  async getUserTreeList({ commit, state }, payload) {
    const resUsers = await apiGetUserList('user', payload.public)
    const resTree = await apiGetGroupTree()
    const resGroupList = await apiGetGroupList()

    commit('updateUserList', resUsers.data)
    commit('updateGroupTree', resTree.data)
    commit('updateGroupList', resGroupList.data)

    const treeList = getFilterUserTreeList(state.userList, state.groupTree, 'infoName')
    commit('updateUserTreeList', treeList)
  },
  async getPrivateDeviceList({ commit }) {
    const resDevices = await apiGetUserList('device', 0) // public: 0
    commit('updatePrivateDeviceList', resDevices.data)
  },
}

const getters = {
  getAccountName: (state) => (userId) => {
    return state.deviceList.find(user => user.id === userId)?.info.name
  },
  getUserGroupId: (state) => (userId) => {
    return state.deviceList.find(user => user.id === userId)?.groupId
  },
  getDeviceTitleId: (state) => (userId) => {
    const device = state.deviceList.find(user => user.id === userId)
    return device ? `${device.video.title} (${device.id})` : `(${userId})`
  },
  getDeviceModelId: (state) => (userId) => {
    const device = state.deviceList.find(user => user.id === userId)
    return device ? device.deviceModelId : null
  },
  getUserTitleId: (state) => (userId) => {
    const user = state.userList.find((item) => item.id === userId)
    return user ? `${user.video.title} (${user.id})` : `(${userId})`
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}